import logo from './logo.svg';
import React,{ useState }from "react"
import './App.css';

function App() {
  const [oc,setoc]=React.useState("");
  const handleFocus = (event) => {
    event.target.select();
  };
  return (
   
    <>
     <div class="container mt-5">
      <div class="row mt-5">
        <div class="col-sm-6">
          <div class="form-outline">
           <textarea class="form-control" id="textAreaExample2" rows="30" name="oc" value={oc} onChange={(e)=>setoc(e.target.value)}></textarea>
            <label class="form-label" for="textAreaExample2">Old Code</label>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-outline">
          <textarea class="form-control" id="textAreaExample3" rows="30" value={oc.replaceAll("[sc_embed_player fileurl=&#8221;","<audio controls preload='none' controlslist='nodownload'><source src='").replaceAll("&#8243;]","' type='audio/mpeg'>Your browser does not support the audio element.</audio>")} onFocus={handleFocus}></textarea>
          <label class="form-label" for="textAreaExample3">New Code</label>
          </div>
          </div>
      </div>
     </div>




    </>
  );
}

export default App;
